import React, { useState } from 'react'
import MainLayout from '../Layouts/Index'
import Request from '../Utils/Request'
import Seo from '../Utils/Seo'
import "../index.css";
import { Box, Center, HStack, Select, Stack, Text } from '@chakra-ui/react'
import Input from '../Components/Input'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import Button from '../Components/Button';
import { GetVerifyInfoApi } from '../Utils/ApiCall';

export default function Home() {

  const [Result, setResult] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [Data, setData] = useState({})
  const [Error, setError] = useState({
    msg: "",
    status: false
  })



  const [Payload, setPayload] = useState({
    identity: "",
    identitycategory: "accountnumber"
  });

  const handlePayload = (e) => {
    setError({
      msg: "",
      status: false
    })

    setPayload({ ...Payload, [e.target.id]: e.target.value })

  }

  const Proceed = async () => {
    // setResult(true)
    setLoading(true)
    try {
      let result = await GetVerifyInfoApi({
        identity: Payload.identity.toString(),
        identitycategory: Payload.identitycategory
      });

      console.log("result", result)
      if (result.data.status) {
        setLoading(false)
        setData(result.data.result)

        setTimeout(() => {
          setResult(true)
          setError({
            msg: result.data.msg,
            status: false
          })
          
        }, 1000);

      }else{
        setLoading(false)
        setError({
          msg: result.data.msg,
          status: true
        })
      }


    } catch (e) {
      setLoading(false)

      console.log("errrorrrr", e)
      
    
    }
  }
  const GOBack = () => {
    setResult(false)
  }

  return (
    <MainLayout pageTitle=''>
      <Seo title='Customer Balance Info' description='Customer Balance Info' />

      <Center>

        <Box bg="#E8E8E8" p={"20px 20px"} w={["85%", "85%", "80%", "70%"]} mt={"64px"} rounded={"10px"}>

          <Text textAlign={"center"} fontWeight={"600"} fontFamily={"sans-serif"} fontSize={["16px","20px","22px","25px"]} color={"#333"}>Verify Customer Feeder Information</Text>

          {
            Result === false ? (
              <Center>
                <Box mt="32px" w={["90%","80%","60%","60%"]}>
                  <Stack spacing={"20px"}>
                    <Stack spacing={"8px"}>
                      <label className='label'>Select Attribute Type</label>
                      <Select _focus={{ borderColor: "blue.blue400" }} borderColor={"#000"} placeholder='Select Attribute Type' fontWeight={"400"} fontSize={Payload.identitycategory != "" ? "16px" : "12px"} id='identitycategory' value={Payload.identitycategory} onChange={handlePayload} >
                        <option value={`accountnumber`}>Account Number</option>
                        <option value={`meternumber`}>Meter Number</option>

                      </Select>
                    </Stack>
                    <Stack spacing={"8px"}>
                      <label className='label'>Enter {Payload.identitycategory === "accountnumber" ? 'Account No' : 'Meter No'} </label>
                      <Input leftIcon={<AiOutlineFieldNumber />} bColor='#000' iconColor="black" labelBg='#E8E8E8' label={Payload.identitycategory === "accountnumber" ? 'Account No' : 'Meter No'} id="identity" type='text' value={Payload.identity} val={Payload.identity != "" ? true : false} onChange={handlePayload} />
                    </Stack>

                    <Button onClick={Proceed} loadingText='Fetching...' isLoading={Loading}>Proceed</Button>

                 

                  </Stack>
                  {
                      Error.status ===true ? (
                        <Text color={"red"} textAlign={"center"} mt="10px">{Error?.msg}</Text>
                      ):("")
                    }



                </Box>
              </Center>
            ) : (
              <Center>
                <Box mt="32px" w={["90%","80%","60%","60%"]}>
                  <Stack spacing={"20px"}>

                    <HStack>
                      <Text fontWeight={"700"} color={"navy"}>Feeder Name</Text>
                      <Text>{Data?.feedername}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={"700"} color={"navy"}>Feeder location</Text>
                      <Text>{Data?.feederlocation}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={"700"} color={"navy"}>Band Type</Text>
                      <Text>{Data?.bandtype}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight={"700"} color={"navy"}>Customer Tariff</Text>
                      <Text>{Data?.customertariff}</Text>
                    </HStack>

                    <Button onClick={GOBack}>Go Back</Button>

                  </Stack>



                </Box>
              </Center>
            )
          }



        </Box>
      </Center>


    </MainLayout>
  )
}
