import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

export default function NavBar() {
  return (
    <Flex bg={"#343D51"} py="10px" px={["3%", "6%"]} justifyContent={'space-between'} alignItems='center' cursor={"pointer"}>
    <a href='https://ekedp.com/' target='blank'>

      <Image src='/logo.png' width={"80px"}/>
    </a>
      <Text color={"#fff"} fontFamily={"sans-serif"} fontSize={["15px","18px","20px","20px"]}>Verify Customer Feeder Information</Text>


    </Flex>
  )
}
