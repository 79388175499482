import axios from "axios";

export const GetVerifyInfoApi = (Payload) => {
console.log("Payload", Payload)



let config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `https://imstest.ekedc.com/api/checktariff/getcustomertariff?apikey=94A3A2B3A26F86FBA2179FF9E76C9kI2j8RdLamWfraakt1UULUzQxLlbnrgM`,
    
    headers: {
        "Content-Type": "application/json",
        
    },
    data: JSON.stringify(Payload),
};

console.log("config", config)
    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {

        console.log("error", error);
        if (error.response.data.msg) {
          throw new Error(error.response.data.msg);
        }
      });
  };
export const GetKCTDataAPI = (Payload) => {
console.log("Payload", Payload)



let config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `https://imstest.ekedc.com/api/checktariff/getkct`,
    
    headers: { 
      'apikey': '94A3A2B3A26F86FBA2179FF9E76C9kI2j8RdLamWfraakt1UULUzQxLlbnrgM', 
      'Content-Type': 'application/json'
    },
  
    data: JSON.stringify(Payload),
};


    return axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {

        console.log("error", error);
        if (error.response.data.msg) {
          throw new Error(error.response.data.msg);
        }
      });
  };