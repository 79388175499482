import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import Input from '../Components/Input'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import Button from '../Components/Button';
import DetailModal from '../Components/DetailModal';
import { GetKCTDataAPI } from '../Utils/ApiCall';
import Seo from '../Utils/Seo';

export default function CheckMeter() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [MeterNo, setMeterNo] = useState("");
    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState({});


    const handleBtn = async()=>{

        setLoading(true)

        try{

            const result = await GetKCTDataAPI({
                meternumber: MeterNo
            });
            console.log("result", result);

            if(result.status === 200){
                setLoading(false)
                setData(result.data.result)
                onOpen()
            }

        }catch(e){
            console.log(e)
        }
        
        setTimeout(() => {
            setLoading(false)
            onOpen()
        }, 2000);

    }
    return (
        <Flex height={"100vh"} justifyContent={"space-between"} flexWrap={"wrap"}>
              <Seo title='Customer KCT Info' description='EKEDC Customer KCT Info' />

            <Box bg="#fffeee" px={"4%"} w={["100%", "100%", "50%", "50%",]} py="32px">
                <a href='https://ekedp.com/' target='blank'>

                    <Image src='/logo.png' width={"80px"} />
                </a>

                <Text mt={["23px","23px","32px","23vh"]} lineHeight={"34px"} fontSize={["28px","30px","33px","33px"]} fontWeight={"700"} fontFamily={"sans-serif"}>Check TID Rollover compliance</Text>
                <Text mt="8px" fontSize={["0.9em","1em","1em","1em"]} fontWeight={"400"} color="#7D838E" fontFamily={"sans-serif"}>Enter your meter number below and click the search button</Text>
                <Box mt="32px">

                <Input  leftIcon={<AiOutlineFieldNumber />} bColor='#01022E' iconColor="#01022E" labelBg='#fffeee' label={"Meter Number"} id="identity" type='text' value={MeterNo} val={MeterNo != "" ? true : false} onChange={(e)=>setMeterNo(e.target.value)} />

                <Button isLoading={Loading} onClick={handleBtn} mt={"32px"}>Search</Button>
                </Box>



            </Box>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} bg="#01022E" px={"4%"} py="32px" w={["100%", "100%", "50%", "50%",]}>
                <Box textAlign={"center"}>
                    <Text fontSize={["22px","22px","25px","25px"]} color={"#fff"} fontWeight={"700"} fontFamily={"sans-serif"}>Welcome to <Box as="span" color={"#CF173C"}>EKEDC MAP APP</Box></Text>
                    <Text mt="10px" fontSize={["16px","17px","18px","19px"]} color={"#fff"} fontWeight={"500"} fontFamily={"sans-serif"}>Request for meters and monitor your application progress
</Text>
                </Box>
            </Box>

            <DetailModal isOpen={isOpen} onClose={onClose} Data={Data}/>
        </Flex>
    )
}
